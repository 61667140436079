<template>
  <div class="container">
    <div class="divider blue"></div>

    <table>
      <tr>
        <td>
          <img :src="logo" alt="Logo" />
        </td>
        <td>
          <div class="header-text">
            <p class="large-text">SELCORP LICITAÇÕES S/A</p>
            <p>
              Rua Augusta, 1168 - Andar 2 Sala 6 - Consolacao<br />
              Cep 01304-001 - São Paulo/SP
            </p>
          </div>
        </td>
      </tr>
    </table>

    <div class="divider"></div>

    <table>
      <tr>
        <td class="block-title">CLIENTE</td>
      </tr>
      <tr>
        <td>CNPJ {{ fatura.cliente.cnpj }}</td>
      </tr>
      <tr>
        <td>{{ fatura.cliente.razaoSocial }}</td>
      </tr>
      <tr>
        <td>{{ fatura.cliente.endereco }} - {{ fatura.cliente.numero }}</td>
      </tr>
      <tr>
        <td>{{ fatura.cliente.bairro }}</td>
      </tr>
      <tr>
        <td>{{ fatura.cliente.municipio }} - {{ fatura.cliente.uf }}</td>
      </tr>
      <tr>
        <td>CEP {{ fatura.cliente.cep }}</td>
      </tr>
    </table>

    <div class="divider-thin"></div>

    <table>
      <tr>
        <th class="l200">Responsável Cobrança:</th>
        <td>{{ fatura.cliente.responsavelCobranca }}</td>
      </tr>
      <tr>
        <th>E-mail Responsável:</th>
        <td>{{ fatura.cliente.emailResponsavel }}</td>
      </tr>
      <tr>
        <th>Data Emissão:</th>
        <td>{{ fatura.dataEmissao }}</td>
      </tr>
      <tr>
        <th>NFE-S:</th>
        <td>
          {{ fatura.numeroNfs }}
          <a target="_blank" :href="fatura.nfsLink">(Ver Nota Fiscal de Serviços)</a>
        </td>
      </tr>
    </table>

    <div class="divider-thin"></div>

    <table>
      <tr>
        <th class="l200">Prestação de Serviço:</th>
        <td>CESSÃO DE USO DA PLATAFORMA {{ webSiteLink }}</td>
      </tr>
    </table>

    <div class="divider-thin"></div>

    <table>
      <tr>
        <th class="l200">Órgão:</th>
        <td>{{ fatura.pregao.orgao }}</td>
      </tr>
      <tr>
        <th>Pregão:</th>
        <td>{{ fatura.pregao.numeroProcesso }}</td>
      </tr>
      <tr>
        <th>Tipo:</th>
        <td>{{ fatura.pregao.tipoPregao }}</td>
      </tr>
      <tr>
        <th>Homologação:</th>
        <td>{{ fatura.pregao.dataHomologacao }}</td>
      </tr>
    </table>

    <div class="divider-thin"></div>

    <table>
      <tr>
        <td class="block-title">FATURA</td>
      </tr>
      <tr>
        <th class="negative l200">TOTAL DA NOTA</th>
        <td class="negative r150">{{ formatarValor(Number(fatura.valorFaturar), 2) }}</td>
        <td></td>
      </tr>
      <tr>
        <th class="negative l00">IMPOSTOS RETIDOS</th>
        <td class="negative r150">{{ formatarValor(fatura.impostos, 2) }}</td>
        <td></td>
      </tr>
      <tr>
        <th class="negative l200">TOTAL A PAGAR</th>
        <td class="negative r150">{{ formatarValor(fatura.valorFaturar - fatura.impostos, 2) }}</td>
        <td></td>
      </tr>
    </table>

    <table>
      <tr>
        <th class="label-parc">Parc</th>
        <th v-for="parcela in fatura.parcelas" :key="parcela.numero" class="data-parc emphasis">
          {{ parcela.numero }}
        </th>
      </tr>
      <tr>
        <th class="label-parc">Vencto</th>
        <td v-for="parcela in fatura.parcelas" :key="parcela.numero" class="data-parc">
          {{ parcela.vencimento }}
        </td>
      </tr>
      <tr>
        <th class="label-parc">Valor</th>
        <td v-for="parcela in fatura.parcelas" :key="parcela.numero" class="data-parc">
          {{ formatarValor(parcela.valor, 2) }}
        </td>
      </tr>
      <tr>
        <th class="label-parc">Boletos</th>
        <td v-for="parcela in fatura.parcelas" :key="parcela.numero" class="data-parc">
          <a target="_blank" :href="parcela.linkBoleto">(BOLETO)</a>
        </td>
      </tr>
    </table>

    <br />
    <table>
      <tr>
        <td class="block-title">DADOS DOS ITENS/LOTES CONTRATADOS</td>
      </tr>
    </table>

    <table>
      <tr>
        <th class="negative">ID</th>
        <th class="negative-item">DESCRIÇÃO</th>
        <th class="negative-item r">P. UNITÁRIO</th>
        <th class="negative-item r">QTDE</th>
        <th class="negative-item r">BASE CALC.</th>
        <th class="negative-item r">PERC</th>
        <th class="negative-item r">VR BRUTO</th>
        <th class="negative-item r">VR TETO</th>
      </tr>
      <tr v-for="item in fatura.itens" :key="item.id">
        <td class="positive-item">{{ item.id }}</td>
        <td class="positive-item">{{ item.descricao }}</td>
        <td class="positive-item r">{{ formatarValor(Number(item.unitario), 2) }}</td>
        <td class="positive-item r">{{ item.quantidade }}</td>
        <td class="positive-item r">
          {{ formatarValor(Number(item.quantidade) * Number(item.unitario), 2) }}
        </td>
        <td class="positive-item r">{{ item.percentual }}%</td>
        <td class="positive-item r">{{ formatarValor(Number(item.valorBruto), 2) }}</td>
        <td class="positive-item r">{{ formatarValor(Number(item.valorFaturar), 2) }}</td>
      </tr>

      <tr>
        <td colspan="4">TOTAL</td>
        <td class="negative-item r">
          <strong>{{
            formatarValor(
              fatura.itens.reduce(
                (total, item) => total + Number(item.unitario) * Number(item.quantidade),
                0,
              ),
              2,
            )
          }}</strong>
        </td>
        <td></td>
        <td class="negative-item r">
          <strong>{{
            formatarValor(
              fatura.itens.reduce((total, item) => total + Number(item.valorBruto), 0),
              2,
            )
          }}</strong>
        </td>
        <td class="negative-item r">
          <strong>{{
            formatarValor(
              fatura.itens.reduce((total, item) => total + Number(item.valorFaturar), 0),
              2,
            )
          }}</strong>
        </td>
      </tr>
    </table>

    <div class="divider-thin"></div>

    <table>
      <tr>
        <td colspan="2" class="block-title">MENSAGEM</td>
      </tr>
      <tr>
        <td colspan="2" class="justify-text">
          Parabenizamos sua empresa pelo sucesso nas contratações e nos orgulhamos de ser a
          plataforma escolhida pelo município. Valorizamos a transparência ao cobrar apenas do
          vencedor, sem imposição de mensalidades ou taxas para todos os participantes nas
          licitações. Estamos sempre abertos a sugestões, elogios e críticas, pois acreditamos que
          seu feedback é essencial para nosso aprimoramento contínuo. Entre em contato conosco pelo
          e-mail: contato@selcorp.com.br ou pelo WhatsApp (14) 9 9164-9190. Agradecemos a parceria e
          reafirmamos nosso compromisso em oferecer soluções eficazes para suas necessidades.
        </td>
      </tr>
    </table>

    <div class="divider">Selcorp - Versão 1.0.0 - 2024</div>
  </div>
</template>

<script>
import logo from '@/assets/logo.png';
import { getInvoice } from '@/resources/admin/invoices';
import { formatarValor, formatarDataSemHorario } from '@/utils/format';

export default {
  name: 'InvoiceView',
  data() {
    return {
      formatarValor,
      formatarDataSemHorario,
      logo,
      headerImageSrc: `https://${process.env.BLOBSTORAGE_ACCOUNT_NAME}.blob.core.windows.net/files/header.png`,
      fatura: {
        cliente: {
          cnpj: '',
          razaoSocial: '',
          endereco: '',
          numero: '',
          bairro: '',
          municipio: '',
          uf: '',
          cep: '',
          responsavelCobranca: '',
          emailResponsavel: '',
        },
        dataEmissao: '',
        numeroNfs: '',
        pregao: {
          orgao: '',
          numeroProcesso: '',
          tipoPregao: '',
          dataHomologacao: '',
        },
        valorFaturar: '',
        impostos: '',
        parcelas: [],
        itens: [],
      },
      webSiteLink: '',
    };
  },
  async created() {
    const response = await getInvoice(this.$route.params.uuid);
    if (response.data) {
      this.fatura = response.data;
    }
  },
  methods: {
    decimalFormat(value) {
      return parseFloat(value).toFixed(2);
    },
  },
};
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: dimgray;
}

.container {
  /* width: 210mm;
  /* A4 width */
  /* height: 297mm;
  /* A4 height */
  margin: auto;
  padding: 5mm;
  box-sizing: border-box;
  background-color: white;
}

.header {
  width: 100%;
}

.header img {
  width: 100%;
  height: auto;
}

.header-text {
  position: relative;
  top: 5px;
  right: 5px;
  color: black;
  text-align: right;
}

.r {
  text-align: right;
}

.header-text p {
  margin: 0;
}

.header-text .large-text {
  font-size: 1.5em;
}

.divider,
.divider-thin {
  height: 2px;
  background-color: #b3261e;
  margin: 20px 0;
  text-align: center;
  font-family: courier;
}

.divider-thin {
  height: 1px;
  margin: 5px 0;
}

.blue {
  background-color: #007be4;
  height: 4px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.block-title {
  display: inline-block;
  background-color: black;
  color: white;
  padding: 2px 5px;
  font-weight: bold;
  margin-bottom: 10px;
}

th,
td {
  border: none;
  padding: 5px;
}

th {
  text-align: left;
  font-weight: bold;
}

.l200 {
  width: 200px;
}

.r150 {
  width: 150px;
  text-align: right;
}

td {
  text-align: left;
}

.negative {
  background: #b3261e;
  color: white;
  padding: 5px;
  border: 1px solid #dcdcdc;
}

.negative-item {
  background: #b3261e;
  color: white;
  padding: 5px;
  border-top: 1px solid white;
  border-right: 1px solid white;
  border-bottom: 1px solid #b3261e;
  border-left: 1px solid #b3261e;
  font-size: 11px;
}

.positive-item {
  padding: 5px;
  border: 1px solid #b3261e;
  font-size: 11px;
}

.positive {
  padding: 5px;
  border: 1px solid #b3261e;
}

.emphasis {
  color: #b3261e;
  font-weight: bold;
}

.justify-text {
  text-align: justify;
}

.label-parc {
  width: 7%;
  font-weight: bold;
  border: 1px solid black;
  font-size: 11px;
}

.data-parc {
  width: 9%;
  border: 1px solid black;
  font-size: 11px;
  text-align: center;
}
</style>
